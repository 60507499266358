import {
  Component,
  OnInit,
  ViewChild,
  ModuleWithComponentFactories,
} from "@angular/core";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MomentDateModule,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeIntl,
} from "ng-pick-datetime";

import { MomentDateTimeAdapter } from "ng-pick-datetime-moment";
import { ActivatedRoute } from "@angular/router";
import { ReportListComponentComponent } from "src/app/component/report-list-component/report-list-component.component";
import { MapComponent } from "src/app/component/map/map.component";
import { ReportService } from "src/app/service/report.service";
import { Report } from "src/app/model/report";
import { GpsMap } from "../../model/gps-map";
import Swal from "sweetalert2";
import { MapService } from "../../service/map.service";
import * as _moment from "moment";
import { ExcelService } from "src/app/service/excel.service";

const moment = _moment;

export const MY_FORMATS = {
  parseInput: "l LT",
  fullPickerInput: "DD/MM/YYYY LT",
  datePickerInput: "l",
  timePickerInput: "LT",
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MMMM YYYY",
};

@Component({
  selector: "app-direcciones-frecuentes-report",
  templateUrl: "./direcciones-frecuentes-report.component.html",
  styleUrls: ["./direcciones-frecuentes-report.component.css"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-CL" },
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DireccionesFrecuentesReportComponent implements OnInit {
  public isRefresh: boolean = false;
  public serialGps: string;
  public total: number;
  public dateInit: Date;
  public dateEnd: Date;

  public indexRute: number;
  public isView: boolean = false;
  public dataList: Report[];

  public opcionSeleccionada;

  public opcionesFiltro = [
    {
      id: 1,
      value: "Las últimas 4 más frecuentes",
    },
    {
      id: 2,
      value: "Las más frecuentes (Últimos 60 días)",
    },
    {
      id: 3,
      value: "Por rango de fecha",
    },
  ];

  @ViewChild("table") table: ReportListComponentComponent;
  @ViewChild("map") map: MapComponent;

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private mapService: MapService,
    private adapter: DateAdapter<any>,
    private excelService: ExcelService
  ) {
    this.dataList = [];
  }

  ngOnInit() {
    this.serialGps = this.route.snapshot.paramMap.get("serial");
    this.total = 0;

    this.indexRute = 0;
    this.suscriber();

    var displayedColumns = ["latitud", "longitud", "geo", "distribucion"];
    this.table.setDisplayedColumns(displayedColumns as string[]);

    this.opcionSeleccionada = 1;
    this.loadDataInit();
  }

  loadData() {
    switch (this.opcionSeleccionada) {
      case 1:
        this.loadDataInit();
        break;
      case 2:
        this.loadDataAllFrecuente();
        break;
      case 3:
        this.loadDataRangoFecha();
        break;
      default:
        this.loadDataAllFrecuente();
        break;
    }
  }

  loadDataAllFrecuente() {
    this.isRefresh = true;

    this.reportService
      .getLastFrequentAddresses(this.serialGps)
      .subscribe((r) => {
        this.callBackReporte(r);
      }, this.callBackError);
  }

  loadDataInit() {
    this.isRefresh = true;

    this.reportService
      .getLastFrequentAddressesByDayDiff(this.serialGps, 4, 30)
      .subscribe((r) => {
        this.callBackReporte(r);
      }, this.callBackError);
  }

  loadDataRangoFecha() {
    if (_moment.isMoment(this.dateInit)) {
      this.dateInit = new Date(this.dateInit.toDate());
    }

    if (_moment.isMoment(this.dateEnd)) {
      this.dateEnd = new Date(this.dateEnd.toDate());
    }

    if (this.dateInit != null && this.dateEnd != null) {
      var dateAux1 = new Date(this.dateInit.toDateString());
      var dateAux2 = new Date(this.dateEnd.toDateString());

      var diffMilisegundo = dateAux2.getTime() - dateAux1.getTime();
      var diffHoras = diffMilisegundo / (1000 * 60 * 60);

      var tiempoMaximoDeConsul = 743; // 744 hrs 31 dias.

      if (diffHoras > tiempoMaximoDeConsul) {
        //47 Horas

        Swal.fire({
          type: "warning",
          title: "Oops...",
          text: "No se puede obtener un reporte con un rango de fecha mayor a 31 días.",
        });
        return;
      }

      this.isRefresh = true;

      this.reportService
        .getLastFrequentAddressesByRango(
          this.serialGps,
          this.dateInit.getTime(),
          this.dateEnd.getTime()
        )
        .subscribe((r) => {
          this.callBackReporte(r);
        }, this.callBackError);
    } else {
      Swal.fire({
        type: "error",
        title: "Una de las fecha no es válida.",
      });
    }
  }

  callBackError(error) {
    Swal.fire({
      type: "warning",
      title: "No se puede obtener la información",
      text: "Intente nuevamente más tarde.",
    });
    this.isRefresh = false;
  }

  callBackReporte(response) {
    this.dataList = response as Report[];

    this.dataList = this.dataList.filter((d) => d.count > 5);

    var totalDistribution = this.dataList
      .map((d) => Number.parseInt(d.count.toString()))
      .reduce((accumulator, count) => accumulator + count, 0);

    this.dataList.forEach((d) => {
      //if (d.geo == null || d.geo == "") {

      this.mapService
        .getDireccionTextAWSByLatLon(d.latitud, d.longitud)
        .subscribe(
          (response) => (d.geo = response["direccion"]),
          (err) => console.log(err)
        );
      //this.mapService.getDireccionTextOpenStreetMapByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['display_name'], err => console.log(err));
      //}
      d.distribucion = Math.round((100 * d.count) / totalDistribution) + "%";
    });

    this.isRefresh = false;
    this.table.setData(this.dataList);
    this.indexRute = 0;
    this.nextView(true);
  }

  suscriber() {
    this.table.emitEventSelectedRow.subscribe((row) =>
      this.selectedRowEvent(row)
    );
  }

  nextView(isNext) {
    if (this.dataList != null && this.dataList.length > 0) {
      if (isNext) {
        if (this.dataList.length > this.indexRute) {
          this.indexRute = this.indexRute + 1;
          if (this.dataList.length == this.indexRute) {
            this.indexRute = 0;
          }
        } else {
          this.indexRute = 0;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      } else {
        if (this.indexRute == 0) {
          this.indexRute = this.dataList.length - 1;
        } else {
          this.indexRute = this.indexRute - 1;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      }
    }
  }

  selectedRowEvent(row: Report) {
    this.map.clearPointReportMap();
    this.map.setPointReportMap(row);
    this.map.centerMap(row.latitud, row.longitud);
  }

  convertToCSV(objArray: any): string {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    var row = "";

    for (var index in objArray[0]) {
      row += index + ";";
    }
    row = row.slice(0, -1);

    str += row + "\r\n";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ";";

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  downloadButtonPush() {
    var filename = "reporte-direcciones-frecuentes-gps-" + this.serialGps;

    const rows = this.dataList.map((d) => {
      return {
        ...d,
        "Link GoogleMap":
          "https://maps.google.com?q=" + d.latitud + "," + d.longitud,
      };
    });

    this.excelService.exportAsExcelFile(rows, filename, false);
  }
}
