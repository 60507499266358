import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xls';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string, head_vacios: boolean = true, text_head: string = ''): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    var origen_inicio = 0;
    if (head_vacios) {
      XLSX.utils.sheet_add_aoa(worksheet,[[text_head]],{ origin :   0 });
      origen_inicio = 1;
    }

    XLSX.utils.sheet_add_json(worksheet,json,{ origin :   origen_inicio })

    const columnWidths = this.calculateColumnWidths(json);
    worksheet['!cols'] = columnWidths;

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    json.forEach((row, rowIndex) => {
      Object.keys(row).forEach((key, colIndex) => {
        const cell = row[key];
        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex + origen_inicio, c: colIndex });
        if (typeof cell === 'string' && cell.startsWith('https://maps.google.com')) {
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].t = "v"; 
            worksheet[cellAddress].v = "Ver ubicación en Google Maps"; 
            worksheet[cellAddress].l = { Target: cell, Tooltip: "Ver ubicación en Google Maps" };
          } 
        }
      });
    });

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private calculateColumnWidths(json: any[]): any[] {
    const columnWidths: any[] = [];
    json.forEach(row => {
      Object.keys(row).forEach((key, colIndex) => {
        const cellValue = row[key];
        const cellLength = cellValue ? cellValue.toString().length : 10;

        if (!columnWidths[colIndex] || columnWidths[colIndex].wch < cellLength) {
          columnWidths[colIndex] = { wch: cellLength };
        }
      });
    });
  
    return columnWidths;
  }
}
