import {
  Component,
  OnInit,
  ViewChild,
  ModuleWithComponentFactories,
  AfterContentInit,
} from "@angular/core";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MomentDateModule,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeIntl,
} from "ng-pick-datetime";

import { MomentDateTimeAdapter } from "ng-pick-datetime-moment";
import swal from "sweetalert2";

import { ActivatedRoute } from "@angular/router";
import { ReportListComponentComponent } from "src/app/component/report-list-component/report-list-component.component";
import { MapComponent } from "src/app/component/map/map.component";
import { ReportService } from "src/app/service/report.service";
import { Report } from "src/app/model/report";
import { GpsMap } from "../../../model/gps-map";
import {
  DatetimeAdapter,
  MAT_DATETIME_FORMATS,
  MAT_NATIVE_DATETIME_FORMATS,
  MatDatetimepickerFilterType,
  NativeDatetimeAdapter,
} from "@mat-datetimepicker/core";

import * as _moment from "moment";
import Swal from "sweetalert2";
import { MapService } from "../../../service/map.service";
import { User } from "../../../model/user";
import { ExcelService } from "src/app/service/excel.service";

const moment = _moment;

export const MY_FORMATS = {
  parseInput: "l LT",
  fullPickerInput: "DD/MM/YYYY LT",
  datePickerInput: "l",
  timePickerInput: "LT",
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MMMM YYYY",
};

type typeReportRuta = "rango" | "periodo";

interface OptionReporteRuta {
  tipo: typeReportRuta;
  texto: string;
  targed: object;
}

interface ReportePreproceRuta {
  id: number;
  operacion: string;
  fecha_actualizacion: string;
  datetime: string;
  reporte: Report[];
  length: number;
  mes: number;
  year: number;
}

@Component({
  selector: "app-pantalla-reporte-bateria-externa",
  templateUrl: "./pantalla-reporte-bateria-externa.component.html",
  styleUrls: ["./pantalla-reporte-bateria-externa.component.css"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-CL" },
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PantallaReporteBateriaExternaComponent
  implements OnInit, AfterContentInit
{
  public isRefresh: boolean = false;
  public serialGps: string;
  public operacionGps: string;
  public imeiId: string;
  public total: number;
  public dateInit: Date;
  public dateEnd: Date;

  public user: User;
  public indexRute: number;
  public isView: boolean = false;
  public dataList: Report[];

  public timeRuta;
  public playEnable = false;
  public stopEnable = false;

  public textDireccionSelect: string;

  public listOptions: OptionReporteRuta[] = [
    {
      tipo: "rango",
      texto: "Rango de fechas (Max. 5 días)",
      targed: {},
    },
  ];

  public opcionSeleccionada: typeReportRuta;

  @ViewChild("table") table: ReportListComponentComponent;
  @ViewChild("map") map: MapComponent;

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private mapService: MapService,
    private adapter: DateAdapter<any>,
    private excelService: ExcelService
  ) {}

  ngAfterContentInit() {
    setTimeout(() => this.map.selectMapSilver(), 1000);
    this.loadData();
  }

  ngOnInit() {
    this.imeiId = this.route.snapshot.paramMap.get("imeiid");

    this.serialGps = this.route.snapshot.paramMap.get("serial");
    this.operacionGps = this.route.snapshot.paramMap.get("operacion");
    this.dateInit = new Date();
    this.dateInit.setDate(this.dateInit.getDate() - 1);
    this.dateEnd = new Date();
    this.total = 0;
    this.user = JSON.parse(localStorage.getItem("user")) as User;

    this.indexRute = 0;

    this.suscriber();
  }

  changeOption(option: OptionReporteRuta) {
    this.isRefresh = true;

    const reportPreprocesado: ReportePreproceRuta =
      option.targed as ReportePreproceRuta;
    this.reportService
      .getByReportRutaPreprocesado(
        this.operacionGps,
        reportPreprocesado.mes,
        reportPreprocesado.year
      )
      .subscribe(
        (response) => {
          const report = response as ReportePreproceRuta;
          this.dataList = report.reporte;
          this.dataList.forEach((d) =>
            setTimeout(() => {
              d.fechaGPS = d.fechaGPS.replace(".000Z", "").replace("T", " ");
              if (d.geo == null || d.geo == "") {
                this.mapService
                  .getDireccionTextByLatLon(d.latitud, d.longitud)
                  .subscribe(
                    (response) => (d.geo = response["direccion"]),
                    (err) => console.log(err)
                  );
                //this.mapService.getDireccionTextOpenStreetMapByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['display_name'], err => console.log(err));
              }
            }, 1)
          );
          this.isRefresh = false;
          this.table.setData(this.dataList);
          var displayedColumns = [
            "fechaGPS",
            "voltaje_externo",
            "velocidad",
            "geo",
          ];
          this.table.setDisplayedColumns(displayedColumns as string[]);
          this.map.setLineByReports(this.dataList);
          this.indexRute = 0;
          this.nextView(true);
          this.playEnable = true;

          this.isRefresh = false;
        },
        (error) => {
          console.error(error);
          this.isRefresh = false;
        }
      );
  }

  loadData() {
    if (_moment.isMoment(this.dateInit)) {
      this.dateInit = new Date(this.dateInit.toDate());
    }

    if (_moment.isMoment(this.dateEnd)) {
      this.dateEnd = new Date(this.dateEnd.toDate());
    }

    if (this.dateInit != null && this.dateEnd != null) {
      var dateAux1 = new Date(this.dateInit.toDateString());
      var dateAux2 = new Date(this.dateEnd.toDateString());

      var diffMilisegundo = dateAux2.getTime() - dateAux1.getTime();
      var diffHoras = diffMilisegundo / (1000 * 60 * 60);

      var tiempoMaximoDeConsul = this.user.rut == "1-9" ? 743 : 120; // 744 hrs 31 dias y 120 horas.

      if (diffHoras > tiempoMaximoDeConsul) {
        //120 Horas = 5 dias

        swal.fire({
          type: "warning",
          title: "Oops...",
          text:
            "No se puede obtener un reporte de ruta con un rango de fecha mayor a " +
            (this.user.rut == "1-9" ? "31" : "5") +
            " días.",
        });
        return;
      }

      this.isRefresh = true;

      this.reportService
        .getRuta(
          this.serialGps,
          this.dateInit.getTime(),
          this.dateEnd.getTime()
        )
        .subscribe(
          (response) => {
            const tempList = response as Report[];

            this.dataList = [];

            //for filtra las tramas repetidas
            for (let i = 0; i < tempList.length; i++) {
              if (i > 0 && i + 1 < tempList.length) {
                let lastIndex = i - 1;

                if (tempList[i].latitud != tempList[lastIndex].latitud) {
                  this.dataList.push(tempList[i]);
                }
              } else {
                this.dataList.push(tempList[i]);
              }
            }

            this.dataList.forEach((d) =>
              setTimeout(() => {
                if (d.geo == null || d.geo == "") {
                  this.mapService
                    .getDireccionTextByLatLon(d.latitud, d.longitud)
                    .subscribe(
                      (response) => (d.geo = response["direccion"]),
                      (err) => console.log(err)
                    );
                  //this.mapService.getDireccionTextOpenStreetMapByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['display_name'], err => console.log(err));
                }
              }, 1)
            );

            this.isRefresh = false;
            this.table.setData(this.dataList);
            var displayedColumns = [
              "fechaGPS",
              "voltaje_externo",
              "ignicion",
              "velocidad",
              "geo",
            ];
            this.table.setDisplayedColumns(displayedColumns as string[]);
            this.map.setLineByReports(this.dataList);
            this.indexRute = 0;
            this.nextView(true);
            this.playEnable = true;

            this.reportService.getHistorialDeConexion(this.imeiId).subscribe(
              (r) => {
                const historicos = r as Report[];

                this.dataList.forEach((d) =>
                  setTimeout(() => {
                    const rows = historicos.filter(
                      (h) => h.fechaEvento == d.fechaGPS
                    );
                    console.log(rows);
                    d.voltaje_externo =
                      rows.length > 0
                        ? rows[0].voltajeExterno
                        : d.voltaje_externo;
                  }, 1)
                );
              },
              (error) => {
                this.isRefresh = false;
              }
            );
          },
          (error) => {
            Swal.fire({
              type: "warning",
              title: "No se puede obtener la información",
              text: "Intente nuevamente más tarde.",
            });
            this.isRefresh = false;
          }
        );
    } else {
      Swal.fire({
        type: "error",
        title: "Una de las fecha no es válida.",
      });
    }
  }

  suscriber() {
    this.table.emitEventSelectedRow.subscribe((row) =>
      this.selectedRowEvent(row)
    );
  }

  playView() {
    this.playEnable = false;
    this.stopEnable = true;
    this.timeRuta = setTimeout(() => {
      this.nextView(true);
      this.playView();
    }, 1500);
  }

  stopView() {
    this.playEnable = true;
    this.stopEnable = false;
    clearTimeout(this.timeRuta);
  }

  nextView(isNext) {
    if (this.dataList != null && this.dataList.length > 0) {
      if (isNext) {
        if (this.dataList.length > this.indexRute) {
          this.indexRute = this.indexRute + 1;
          if (this.dataList.length == this.indexRute) {
            this.indexRute = 0;
          }
        } else {
          this.indexRute = 0;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      } else {
        if (this.indexRute == 0) {
          this.indexRute = this.dataList.length - 1;
        } else {
          this.indexRute = this.indexRute - 1;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      }
    }
  }

  selectedRowEvent(row: Report) {
    this.map.clearPointReportMap();
    this.map.setPointReportMap(row);
    this.map.centerMap(row.latitud, row.longitud);
    this.textDireccionSelect = row.geo;
  }

  convertToCSV(objArray: any): string {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    var row = "";

    for (var index in objArray[0]) {
      row += index + ";";
    }
    row = row.slice(0, -1);

    str += row + "\r\n";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ";";

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  downloadButtonPush() {
    var filename = "reporte-bateria-externa-gps-" + this.serialGps;

    const rows = this.dataList.map((d) => {
      return {
        ...d,
        "Link GoogleMap":
          "https://maps.google.com?q=" + d.latitud + "," + d.longitud,
      };
    });

    this.excelService.exportAsExcelFile(rows, filename, false);
  }

  isMovil = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
}
