import {
  Component,
  OnInit,
  ViewChild,
  ModuleWithComponentFactories,
} from "@angular/core";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MomentDateModule,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeIntl,
} from "ng-pick-datetime";

import { MomentDateTimeAdapter } from "ng-pick-datetime-moment";
import swal from "sweetalert2";

import { ActivatedRoute } from "@angular/router";
import { ReportListComponentComponent } from "src/app/component/report-list-component/report-list-component.component";
import { MapComponent } from "src/app/component/map/map.component";
import { ReportService } from "src/app/service/report.service";
import { Report } from "src/app/model/report";
import { GpsMap } from "../../model/gps-map";
import {
  DatetimeAdapter,
  MAT_DATETIME_FORMATS,
  MAT_NATIVE_DATETIME_FORMATS,
  MatDatetimepickerFilterType,
  NativeDatetimeAdapter,
} from "@mat-datetimepicker/core";

import * as _moment from "moment";
import Swal from "sweetalert2";
import { MapService } from "../../service/map.service";
import { User } from "../../model/user";
import { ExcelService } from "src/app/service/excel.service";

const moment = _moment;

export const MY_FORMATS = {
  parseInput: "l LT",
  fullPickerInput: "DD/MM/YYYY LT",
  datePickerInput: "l",
  timePickerInput: "LT",
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MMMM YYYY",
};

@Component({
  selector: "app-exceso-velocidades-report",
  templateUrl: "./exceso-velocidades-report.component.html",
  styleUrls: ["./exceso-velocidades-report.component.css"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-CL" },
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ExcesoVelocidadesReportComponent implements OnInit {
  public isRefresh: boolean = false;
  public serialGps: string;
  public total: number;
  public dateInit: Date;
  public dateEnd: Date;

  public user: User;
  public indexRute: number;
  public isView: boolean = false;
  public dataList: Report[];

  public timeRuta;
  public playEnable = false;
  public stopEnable = false;

  public textDireccionSelect: string;

  @ViewChild("table") table: ReportListComponentComponent;
  @ViewChild("map") map: MapComponent;

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private mapService: MapService,
    private adapter: DateAdapter<any>,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.serialGps = this.route.snapshot.paramMap.get("serial");
    this.dateInit = new Date();
    this.dateInit.setDate(this.dateInit.getDate() - 1);
    this.dateEnd = new Date();
    this.total = 0;
    this.user = JSON.parse(localStorage.getItem("user")) as User;

    this.indexRute = 0;

    this.suscriber();

    this.loadDataTop();
  }

  loadDataTop() {
    this.isRefresh = true;

    this.reportService.getExcesosVelocidadesTop(this.serialGps).subscribe(
      (response) => {
        this.dataList = response as Report[];

        this.dataList.forEach((d) =>
          setTimeout(() => {
            if (d.geo == null || d.geo == "") {
              this.mapService
                .getDireccionTextByLatLon(d.latitud, d.longitud)
                .subscribe(
                  (response) => (d.geo = response["direccion"]),
                  (err) => console.log(err)
                );
              //this.mapService.getDireccionTextOpenStreetMapByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['display_name'], err => console.log(err));
            }
          }, 1)
        );

        this.isRefresh = false;
        this.table.setData(this.dataList);
        var displayedColumns = [
          "geo",
          "fechaGPS",
          "velocidad",
          "velocidadLimite",
        ];
        this.table.setDisplayedColumns(displayedColumns as string[]);
        //this.map.setLineByReports(this.dataList);
        this.indexRute = 0;
        this.nextView(true);
        this.playEnable = true;
      },
      (error) => {
        Swal.fire({
          type: "warning",
          title: "No se puede obtener la información",
          text: "Intente nuevamente más tarde.",
        });
        this.isRefresh = false;
      }
    );
  }

  loadData() {
    if (_moment.isMoment(this.dateInit)) {
      this.dateInit = new Date(this.dateInit.toDate());
    }

    if (_moment.isMoment(this.dateEnd)) {
      this.dateEnd = new Date(this.dateEnd.toDate());
    }

    if (this.dateInit != null && this.dateEnd != null) {
      var dateAux1 = new Date(this.dateInit.toDateString());
      var dateAux2 = new Date(this.dateEnd.toDateString());

      var diffMilisegundo = dateAux2.getTime() - dateAux1.getTime();
      var diffHoras = diffMilisegundo / (1000 * 60 * 60);

      var tiempoMaximoDeConsul = 743; // 744 hrs 31 dias.

      if (diffHoras > tiempoMaximoDeConsul) {
        //744 Horas

        swal.fire({
          type: "warning",
          title: "Oops...",
          text: "No se puede obtener el reporte de excesos de velocidad con un rango de fecha mayor a 31 días.",
        });
        return;
      }

      this.isRefresh = true;

      this.reportService
        .getExcesosVelocidades(
          this.serialGps,
          this.dateInit.getTime(),
          this.dateEnd.getTime()
        )
        .subscribe(
          (response) => {
            this.dataList = response as Report[];

            this.dataList.forEach((d) =>
              setTimeout(() => {
                if (d.geo == null || d.geo == "") {
                  this.mapService
                    .getDireccionTextByLatLon(d.latitud, d.longitud)
                    .subscribe(
                      (response) => (d.geo = response["direccion"]),
                      (err) => console.log(err)
                    );
                  //this.mapService.getDireccionTextOpenStreetMapByLatLon(d.latitud, d.longitud).subscribe( response => d.geo = response['display_name'], err => console.log(err));
                }
              }, 1)
            );

            this.isRefresh = false;
            this.table.setData(this.dataList);
            var displayedColumns = [
              "geo",
              "fechaGPS",
              "velocidad",
              "velocidadLimite",
            ];
            this.table.setDisplayedColumns(displayedColumns as string[]);
            //this.map.setLineByReports(this.dataList);
            this.indexRute = 0;
            this.nextView(true);
            this.playEnable = true;
          },
          (error) => {
            Swal.fire({
              type: "warning",
              title: "No se puede obtener la información",
              text: "Intente nuevamente más tarde.",
            });
            this.isRefresh = false;
          }
        );
    } else {
      Swal.fire({
        type: "error",
        title: "Una de las fecha no es válida.",
      });
    }
  }

  suscriber() {
    this.table.emitEventSelectedRow.subscribe((row) =>
      this.selectedRowEvent(row)
    );
  }

  playView() {
    this.playEnable = false;
    this.stopEnable = true;
    this.timeRuta = setTimeout(() => {
      this.nextView(true);
      this.playView();
    }, 1500);
  }

  stopView() {
    this.playEnable = true;
    this.stopEnable = false;
    clearTimeout(this.timeRuta);
  }

  nextView(isNext) {
    if (this.dataList != null && this.dataList.length > 0) {
      if (isNext) {
        if (this.dataList.length > this.indexRute) {
          this.indexRute = this.indexRute + 1;
          if (this.dataList.length == this.indexRute) {
            this.indexRute = 0;
          }
        } else {
          this.indexRute = 0;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      } else {
        if (this.indexRute == 0) {
          this.indexRute = this.dataList.length - 1;
        } else {
          this.indexRute = this.indexRute - 1;
        }
        this.selectedRowEvent(this.dataList[this.indexRute]);
      }
    }
  }

  selectedRowEvent(row: Report) {
    this.map.clearPointReportMap();
    this.map.setPointReportMap(row);
    this.map.centerMap(row.latitud, row.longitud);
    this.textDireccionSelect = row.geo;
  }

  convertToCSV(objArray: any): string {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    var row = "";

    for (var index in objArray[0]) {
      row += index + ";";
    }
    row = row.slice(0, -1);

    str += row + "\r\n";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ";";

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  downloadButtonPush() {
    var filename = "reporte-exceso-velocidad-gps-" + this.serialGps;

    const rows = this.dataList.map((d) => {
      return {
        ...d,
        "Link GoogleMap":
          "https://maps.google.com?q=" + d.latitud + "," + d.longitud,
      };
    });

    this.excelService.exportAsExcelFile(rows, filename, false);
  }
}
